import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/Ceremony/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/Ceremony/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/Ceremony/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/Ceremony/4.jpg';
import p5 from 'assests/Photos/SchoolProgram/Ceremony/5.jpg';
import p6 from 'assests/Photos/SchoolProgram/Ceremony/6.jpg';
import p7 from 'assests/Photos/SchoolProgram/Ceremony/7.jpg';
import p8 from 'assests/Photos/SchoolProgram/Ceremony/8.jpg';







import { Typography } from '@mui/material';
import Container from 'components/Container';

import SidebarArticles from 'views/SchoolProgram/components/SidebarArticles/SidebarArticles';

const Ceremony2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 2,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 2,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
  
  
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 2,
    },
    {
      src: p7,
      source:p7,
      rows: 1,
      cols: 1,
    },
    {
      src: p5,
      source: p5,
      rows: 1,
      cols: 2,
    },

  
  
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 2,
      },
   
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 2,
      },
      
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Annual Prize Distribution ceremony
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
CLASS: 9-12 Date: 30 January 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The culmination of the co scholastic events for the academic year 2022-23 was marked by conducting the
        Annual Prize Distribution ceremony for classes 9-12 at National Public School, Yeshwanthpur on 30 January
        2023. The ceremony was held to felicitate the students who are inspired by the school’s motto ‘Reach Out,
        Reach High, Reach beyond’ in various competitions and sport events. It was a proud moment for all the
        winners as they are a source of motivation. 
      <br/><br/>
      The certificates were given to a total of 104 winners of various Association activities. Medals and certificates
were conferred upon a total of 181 winners of various Sport events. The teachers and the students
encouraged the winners with a thunderous applause as they walked up the stage to collect prizes for their
respective areas of excellence.
   <br/><br/>
  As the ceremony drew to a close, the Principals appreciated and congratulated all the prize-winners for their
achievements. They advised that many more students should participate and appreciate the opportunities
given to them.

<br/></Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“You’re not obligated to win. You’re obligated to keep trying to the best you can do everyday”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={4}
          rowHeight={isMd ? 220 : 300}
          rowWeight={isMd ? 200 : 300}

          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>

<br/>
        </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default Ceremony2023;